import React, { useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';
import { Layout } from '@components';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { mixins, Main } from '@styles';

const StyledMainContainer = styled(Main)`
  ${mixins.flexCenter};
  flex-direction: column;
  margin-top: 140px;
  align-items: flex-start;
`;

const StyledLink = styled.a`
  word-break: break-all;
`;

const PrivacyPolicy = ({ location }) => {
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  return (
    <Layout location={location}>
      <TransitionGroup component={null}>
        <CSSTransition timeout={500} classNames="fade">
          <StyledMainContainer className="fillHeight">
            <h1>Privacy Policy for lexer.dev</h1>
            <p>At lexer.dev, accessible from https://lexer.dev, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by lexer.dev and how we use it.</p>
            <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
            <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in lexer.dev. This policy is not applicable to any information collected offline or via channels other than this website.</p>
            <h2>Consent</h2>
            <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
            <h2>Information we collect</h2>
            <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
            <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
            <p>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
            <h2>How we use your information</h2>
            <p>We use the information we collect in various ways, including to:</p>
            <ul>
              <li>Improve, personalize, and expand our website</li>
              <li>Understand and analyze how you use our website</li>
              <li>Develop new products, services, features, and functionality</li>
              <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
              <li>Send you emails</li>
              <li>Find and prevent fraud</li>
            </ul>

            <h2>Log Files</h2>
            <p>lexer.dev follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information. Our Privacy Policy was created with the help of the <a href="https://www.privacypolicygenerator.info">Privacy Policy Generator</a> and the <a href="https://www.privacypolicytemplate.net/">Privacy Policy Template</a>.</p>
            <p>When you use our Website, we automatically collect certain computer information by the interaction of your mobile phone or web browser with our Website. Such information is typically considered non-personal information. We also collect the following:</p>

            <h2>Log Information</h2>
            <p>We automatically receive information from your web browser or mobile device. This information includes the name of the website from which you entered our Website, if any, as well as the name of the website to which you're headed when you leave our website. This information also includes the IP address of your computer/proxy server that you use to access the Internet, your Internet Website provider name, web browser type, type of mobile device, and computer operating system. We use all of this information to analyze trends among our Users to help improve our Website.</p>

            <h2>Service Providers</h2>
            <p>We employ third party companies and individuals to facilitate our Website ('Service Providers') to provide our Website on our behalf, to perform Website-related services or to assist us in analyzing how our Website is used. These third-parties have access to your personal information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
            <h3>Analytics</h3>
            <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.</p>
            <p>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.</p>
            <p>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page: <StyledLink href="https://www.google.com/intl/en/policies/privacy/">https://www.google.com/intl/en/policies/privacy/</StyledLink></p>
            <p>For more information on the privacy practices of Google Analytics for Firebase, please visit the Google Analytics for Firebase Use Policy &amp; Terms web page: <StyledLink href="https://firebase.google.com/policies/analytics/">https://firebase.google.com/policies/analytics</StyledLink></p>


            <h2>Cookies and Web Beacons</h2>
            <p>Like any other website, lexer.dev uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
            <p>For more general information on cookies, please read <a href="https://www.cookieconsent.com/what-are-cookies/">'What Are Cookies'</a>.</p>
            <h2>Advertising Partners Privacy Policies</h2>
            <p>You may consult this list to find the Privacy Policy for each of the advertising partners of lexer.dev.</p>
            <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on lexer.dev, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>
            <p>Note that lexer.dev has no access to or control over these cookies that are used by third-party advertisers.</p>
            <h2>Third Party Privacy Policies</h2>
            <p>lexer.dev's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.</p>
            <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. What Are Cookies?</p>
            <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
            <p>Under the CCPA, among other rights, California consumers have the right to:</p>
            <ul>
              <li>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</li>
              <li>Request that a business delete any personal data about the consumer that a business has collected.</li>
              <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
            </ul>
            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>


            <h2>Information Regarding Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h2>
            <p>For the purpose of this Privacy Policy, we are a Data Controller of your personal information.</p>
            <p>If you are from the European Economic Area (EEA), our legal basis for collecting and using your personal information, as described in this Privacy Policy, depends on the information we collect and the specific context in which we collect it. We may process your personal information because:</p>
            <ul>
              <li>We need to perform a contract with you, such as when you use our services</li>
              <li>You have given us permission to do so</li>
              <li>The processing is in our legitimate interests and it's not overridden by your rights</li>
              <li>For payment processing purposes</li>
              <li>To comply with the law</li>
            </ul>

            <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. In certain circumstances, you have the following data protection rights:</p>
            <ul>
              <li>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</li>
              <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</li>
              <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
              <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
              <li>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
              <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
            </ul>
            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
            <p>Please note that we may ask you to verify your identity before responding to such requests.</p>
            <p>You have the right to complain to a Data Protection Authority about our collection and use of your personal information. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>

            <h2>Children's Information</h2>
            <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
            <p>lexer.dev does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>

            <h3>Contacting Us</h3>
            <p>If there are any questions regarding this privacy policy you may contact us.</p>
          </StyledMainContainer>
        </CSSTransition>
      </TransitionGroup>
    </Layout>
  );
};


PrivacyPolicy.propTypes = {
  location: PropTypes.object.isRequired,
};

export default PrivacyPolicy;
